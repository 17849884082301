import StyledLogo from "./StyledLogo"; 
import {Box, Divider, Container, Icon, Flex, HStack, VStack, Stack, Image, Button, Link, useDisclosure, Drawer, DrawerOverlay,DrawerContent,DrawerHeader,DrawerBody, DrawerCloseButton} from "@chakra-ui/react"
import { NavLink as ReactLink } from "react-router-dom"
import styled from '@emotion/styled'
import React, {useRef} from 'react'
import { AiOutlineMenu } from 'react-icons/ai'


export default function Navbar(){

    const { isOpen, onOpen, onClose } = useDisclosure()
    const firstField = useRef()
  
    const NavBar = () => (
        <Flex as="nav" flexDirection="column"  alignItems="center" justifyContent="center" my="4">
            <StyledLogo/>
                <HStack
                    id="styled-component"
                    className='tomorrow-font-navbar'
                    justifyContent="space-evenly" 
                    width="1048px"
                    py="3px" 
                    color="#FFFFFF" borderRadius="17px" bg="rgba(75, 75, 75, 0.71)"  
                >   
                        <ReactLink exact to='/' activeClassName="nav-active" >
                            <Button as="a" variant="none" href="/" _focus="none" id="button-nav" fontSize="15px">
                                Home
                            </Button>
                        </ReactLink>
                        <ReactLink exact to='/mint' activeClassName="nav-active">
                            <Button as="a" variant="none" href="/mint" _focus="none" id="button-nav" fontSize="15px">
                                Buy a Snail
                            </Button>                
                        </ReactLink>
                        <ReactLink exact to='/roadmap' activeClassName="nav-active">
                            <Button as="a" variant="none" href="/roadmap" _focus="none" id="button-nav" fontSize="15px">
                                Roadmap
                            </Button>                  
                        </ReactLink>
                        <ReactLink exact to='team' activeClassName="nav-active">
                            <Button as="a" variant="none" href="/team" _focus="none" id="button-nav" fontSize="15px">
                                Team
                            </Button>                  
                        </ReactLink>
                        <ReactLink exact to='/racetrack' activeClassName="nav-active">
                            <Button as="a" variant="none" href="/racetrack" _focus="none" id="button-nav" fontSize="15px">
                                Racetrack
                            </Button>                  
                        </ReactLink>
                        <ReactLink exact to='/provenance' activeClassName="nav-active">
                            <Button as="a" variant="none" href="/provenance" _focus="none" id="button-nav" fontSize="15px">
                                Provenance
                            </Button>                  
                        </ReactLink>
                        <ReactLink exact to='/members'activeClassName="nav-active" >
                            <Button as="a" variant="none" href="/members" _focus="none" id="button-nav" fontSize="15px">
                                Members
                            </Button>                 
                        </ReactLink>
                </HStack>
                <hr id="divider" width="988px" />

        </Flex>
    )

    
    const MobileNavBar = () => (
        <>
          <Drawer
            isOpen={isOpen}
            placement="right"
            initialFocusRef={firstField}
            onClose={onClose}
          >
            <DrawerOverlay />
            <DrawerContent  backgroundColor="#666666">
              <DrawerCloseButton />
              <DrawerHeader borderBottomWidth="1px">
                <Box pl="15%" width="78%" height="78%">
                        <img
                            src=
                            "/config/images/logo.png"
                            alt='Lucky Snails Racing Club'
                            outline="none"
                        />
                </Box>
              </DrawerHeader>
    
              <DrawerBody  align="center">
                <Stack pt="20px" spacing="20px" textTransform="uppercase" textColor="#FFFFFF" className="tomorrow-font" onClick={onClose}>
                    <ReactLink exact to='/' activeClassName="nav-active">
                        <Button as="a" href="/" _focus="none" variant="none" >
                            Home
                        </Button>
                    </ReactLink>
                    <ReactLink exact to='/mint' href="/mint" _focus="none"  activeClassName="nav-active">
                        <Button as="a" variant="none">
                            Buy a Snail
                        </Button>                
                    </ReactLink>
                    <ReactLink exact to='/roadmap' href="/roadmap" _focus="none" activeClassName="nav-active">
                        <Button as="a" variant="none">
                            Roadmap
                        </Button>                  
                    </ReactLink>
                    <ReactLink exact to='team' href="/team" _focus="none" activeClassName="nav-active">
                        <Button as="a" variant="none">
                            Team
                        </Button>                  
                    </ReactLink>
                    <ReactLink exact to='/racetrack' href="/racetrack" _focus="none" activeClassName="nav-active">
                        <Button as="a" variant="none">
                            Racetrack
                        </Button>                  
                    </ReactLink>
                    <ReactLink exact to='/provenance' href="/provenance" _focus="none" activeClassName="nav-active">
                        <Button as="a" variant="none">
                            Provenance
                        </Button>                  
                    </ReactLink>
                    <ReactLink exact to='/members' href="/members" _focus="none" activeClassName="nav-active">
                        <Button as="a" variant="none">
                            Members
                        </Button>                 
                    </ReactLink>
                </Stack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
    )
    return (
        <Box>
            <Box id="home-navbar-display"padding="45px"><NavBar/></Box>
            <Flex id = "home-mobile-navbar-display" justifyContent="space-between" padding="5%">
                <Box/>
                <Box id="logo-mobile" minWidth="180px">
                    <ReactLink exact to='/' activeClassName="nav-active">
                        <img
                            src=
                            "/config/images/logo.png"
                            alt='Lucky Snails Racing Club'
                            outline="none"
                        />
                    </ReactLink>
                </Box>
                <Box>
                    <Icon id="nav-icon" color="white" as={AiOutlineMenu} w={8} h={8} onClick={onOpen} alignSelf="center" cursor="pointer" position="fixed" top="2em" right="2em"/>
                </Box>
            </Flex>
            <Drawer/>
            <MobileNavBar/>
        </Box>
    )
}