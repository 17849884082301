import * as React from 'react'
import { Flex, Text, Image} from "@chakra-ui/react"
import StyledContainer from './StyledContainer'

export default function Loading () {    
    return (
        <StyledContainer child={
            <Flex direction="column" align="center" py="3%">
                <Image maxWidth="50%"maxHeight="35%"
                    src=
                    "/config/images/moving_snail.gif"
                />
                <Text align="center" id="loading" className="tomorrow-bold-white-26px" fontSize="6xl">
                    Loading...
                </Text>
            </Flex>

        }/>
    )
}