import { Container, Box, Stack, Flex, ButtonGroup, IconButton, Input, Link, Text, Center} from "@chakra-ui/react";
import { FaTwitter, FaInstagram, FaDiscord, FaTiktok} from "react-icons/fa"
import { Link as ReactLink } from "react-router-dom"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import '../assets/style.css'
import SubscribeButton from './SubscribeButton'
import useMediaQuery from '../hooks/useMediaQuery'


export default function Footer() {
    const isLargerThan768 = useMediaQuery(768)

    return (
        <Box id="footer-parent"
        as="footer"
        role="contentinfo"
        mx="auto"
        alignContent='center'
        py={isLargerThan768 ? "3%" : "6%"}
        px={{ base: '4', md: '6%' }}
        >
                <Flex
                    direction={isLargerThan768 ? "row" : "column"}
                    justify="space-between"
                    align="center"
                >   
                <Center>
                    <Flex direction="column" float="left" >
                        <Box>
                            <p align={isLargerThan768 ? "left" : "center"} style={{color: 'white'}} className="tomorrow-font-footer"> GET ON THE LIST</p>
                            <Box my="1em">
                                <SubscribeButton />
                            </Box>
                        </Box>
                    </Flex>
                </Center>
                    <Flex direction="column" align={isLargerThan768 ? "right" : "center"} >
                        <ButtonGroup variant="none" id="button-group" spacing={isLargerThan768 ? ".05%" : "-10px"}>
                            <IconButton
                                as="a"
                                href="https://twitter.com/LuckySnailsNFT"
                                aria-label="Twitter"
                                icon={<FaTwitter fontSize="18px" />}
                            />
                            <IconButton
                                as="a"
                                href="https://www.instagram.com/luckysnailsracingclub/"
                                aria-label="Instagram"
                                icon={<FaInstagram fontSize="18px" />}
                            />
                            <IconButton
                                as="a"
                                href="https://discord.gg/lsrc"
                                aria-label="Discord"
                                icon={<FaDiscord fontSize="18px" />}
                            />
                        </ButtonGroup>
                        <Box>
                            <Text style={{textAlign: "right", fontFamily:"tomorrow", marginTop: "1em", color: "white"}}> © 2022 Brosh Investment Group LLC</Text>
                        </Box>
                        <Link as={ReactLink} to='/lsrc/toc' style={{textAlign: "right", fontFamily:"tomorrow", marginTop: ".5em", color: "white"}}>
                            <Text as="u"> LSRC Terms and Conditions</Text>
                        </Link>
                    </Flex>
                </Flex>
        </Box>
    )
}
