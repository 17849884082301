import {Box, Image} from '@chakra-ui/react'
import { NavLink as ReactLink } from "react-router-dom"

export default function StyledLogo() {
    return (
        <Box width="254px" height="109px" paddingBottom="150px" >
            <ReactLink exact to='/' activeClassName="nav-active">
                <img
                    src=
                    "/config/images/logo.png"
                    alt='Lucky Snails Racing Club'
                    outline="none"
                />
            </ReactLink>
        </Box>

    )
}