import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const config = {
  useSystemColorMode: true,
};

const components = {
  Link : {
    baseStyle: {
      color: '#FFFFFF',
      _hover: { color: '#D3C352', textDecoration: 'none'},
    }
  },
  Button : {
    baseStyle: {
      _hover: { color: '#D3C352', textDecoration: 'none'},
  //     need to fix
  //     _active:{
  //       bg: '#dddfe2',
  //       transform: 'scale(0.98)',
  //       borderColor: '#bec3c9',
  //     }
  }
}}
const styles = {
  global: (props) => ({
    body: {
      bg: mode("#4B4B4B")(props),
    },
  }),
};

const theme = extendTheme({ ...config, styles, components });

export default theme;