import * as React from 'react'
import { 
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import Navbar from './components/Navbar'
import Loading from './components/Loading'
import Footer from './components/Footer'
import { Helmet } from "react-helmet"

const Home = React.lazy(() => import('./pages/Home'))
const Members = React.lazy(() => import('./pages/Members'))
const MintNFT = React.lazy(() => import('./pages/MintNFT'))
const Provenance = React.lazy(() => import('./pages/Provenance'))
const RaceTrack = React.lazy(() => import('./pages/RaceTrack'))
const RoadMap = React.lazy(() => import('./pages/RoadMap'))
const Team = React.lazy(() => import('./pages/Team'))
const ToC = React.lazy(() => import('./pages/ToC'))
const Subscribed = React.lazy(() => import('./pages/Subscribed'))


export default function App() {
    return (
        <div id="app">
            <Router>
                <Helmet> 
                    <title>Lucky Snails Racing Club</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="description" content="Lucky Snails Racing Club is a collection of 10,000 NFTs on the Ethereum blockchain. These Snails are divided into 3 main breeds (Formula, Endurance, and Street). We're giving away Tesla Model 3's, BMW M4, Qudi Q3, and more. Mint your Lucky Snails Racing Club NFT." data-react-helmet="true" />
                    <meta name="keyword" content="NFT, NFTs, ETH, Etherium, Bored Ape Yacht Club, Lucky Snails, Opensea, Crypto, NFT Crypto, Ape NFT, Racing Club, Non-fungible token, Moon, Token, Roadmap, Project" data-react-helmet="true" />
                </Helmet>
                <div id="main-content">
                <Navbar/>
                    <React.Suspense fallback={<Loading/>}>
                    <Switch>  

                        <Route path='/' exact>
                            <Home/>
                        </Route>
                        <Route path='/members'>
                            <Members />
                        </Route>
                        <Route path='/mint'>
                            <MintNFT/>
                        </Route>
                        <Route path='/provenance' exact>
                            <Provenance />
                        </Route>
                        <Route path='/racetrack'>
                            <RaceTrack />
                        </Route>
                        <Route path='/roadmap'>
                            <RoadMap />
                        </Route>
                        <Route path='/team'>
                            <Team />
                        </Route>
                        <Route path='/lsrc/toc'>
                            <ToC/>
                        </Route>
                        <Route path='/subscribed'>
                            <Subscribed/>
                        </Route>
                        <Route path='*'>
                            <ToC/>
                        </Route>
                    </Switch>
                    </React.Suspense>
                    </div>
                    <Footer/>
            </Router>
        </div>
    )
}