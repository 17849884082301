import { Box } from "@chakra-ui/react"
import useMediaQuery from '../hooks/useMediaQuery'

export default function StyledContainer({child}) {
    const isLargerThan1980 = useMediaQuery(1980)
    const isLargerThan1180 = useMediaQuery(1180)
    const isLargerThan768 = useMediaQuery(768)

    return <Box id="styled-component" borderRadius='25px' 
    bg="rgba(63, 59, 59, 0.9)" 
    px={isLargerThan1180 ? "3em" : "2em"} 
    py="2.5em" width="1152px"  m=" 0 auto 3em auto" backgroundBlendMode="pass" >
        { child }
    </Box>
}       