import React, { Component } from "react";
import ReactDOM from 'react-dom'
import {Flex} from '@chakra-ui/react'

class SubscribeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  render(){
    return (
        <div id="mc_embed_signup">
        <form action="https://luckysnailsracingclub.us20.list-manage.com/subscribe/post?u=530b5f339c45643a86f255639&amp;id=74bf47ea23" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
          <div >
            <input placeholder="Email Address" type="email" value={this.state.value} onChange={this.handleChange} name="EMAIL" className="required email" id="mce-EMAIL"/>
          </div>
          <div id="mce-responses" >
              <div cid="mce-error-response" style={{display:"none"}}></div>
              <div id="mce-success-response" style={{display:"none"}}></div>
            </div>    
              <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_530b5f339c45643a86f255639_74bf47ea23" tabIndex="-1" value=""/></div>
                  <div>
                      <div>
                          <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" />
                      </div>
                  </div>
              </div>
          </form>
          </div>
    );
  }
}


export default SubscribeButton;